// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:17+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            c2338d289f2bbb03ac93014b77ad3d5e
//    Signature:      eyJpdiI6ImpHc0Vzc0dQaTNuV0RVbnhhdjlYZlE9PSIsInZhbHVlIjoiMWt0NUUzQnlIcDVwb25Gc09VXC9lMWpMbWE3TEN2YnJJS1FwMEZoNGVhQ2k1dkRMdFM2R2ZxZkFpaDgrZ0MyUEpcL3BJOU9uZXlDbk1IRjAwN0c3QTJVUkY5am1uZnR4a1FtcHUrSENBNFdEalczMjNNRWRxdUhZTnMrdVN5dWFjcnFqdFJcL05HZHRwWTMzTkpOalVUckJVTFBTeHNhU3NhOW1Ud1FmQnM4dHE0c0w5Z24rejVSY0xUSW15UDlNNGhFU1dmQlJhamlpb0p1dklSd2sxZlFOSlwvOFJ3TmF5SXplR2ZDNW1VcnlZNzNra2Q4dmRIRjhUdnVQdW9jcmo5K0ZhM2NJVXc0ZFR1SXdFR3Y5a2QxNWZpK1wvVkdta3d0MkdDMTM3U1FRNG8yYm9kMFNLMjB3anBxNEJoM2V0SysydUY0dFZxYWVcL2t3UTZhR2djSmJGVWVmVEdzeTRHV0dVcVwvYjlNWVNUZk1FeHFUMUZaQlpnZmxDVW5wSTNyQlhhS2J4M1RlWXdNZjRwM3NYK1BWZE5mWHc9PSIsIm1hYyI6IjdkNjNlNzY3MGJiZGEyNTZhYTk4NmQ1ZDU4NTVlYzE0MDBjNjJiM2ZiNDBiYzYyOTc4YmMyYjE3OTA1YTE3YzQifQ==
class DetailsDisclosure extends HTMLElement {
  constructor() {
    super();
    this.mainDetailsToggle = this.querySelector('details');
    this.content = this.mainDetailsToggle.querySelector('summary').nextElementSibling;

    this.mainDetailsToggle.addEventListener('focusout', this.onFocusOut.bind(this));
    this.mainDetailsToggle.addEventListener('toggle', this.onToggle.bind(this));
  }

  onFocusOut() {
    setTimeout(() => {
      if (!this.contains(document.activeElement)) this.close();
    })
  }

  onToggle() {
    if (!this.animations) this.animations = this.content.getAnimations();

    if (this.mainDetailsToggle.hasAttribute('open')) {
      this.animations.forEach(animation => animation.play());
    } else {
      this.animations.forEach(animation => animation.cancel());
    }
  }

  close() {
    this.mainDetailsToggle.removeAttribute('open');
    this.mainDetailsToggle.querySelector('summary').setAttribute('aria-expanded', false);
  }
}

customElements.define('details-disclosure', DetailsDisclosure);

class HeaderMenu extends DetailsDisclosure {
  constructor() {
    super();
    this.header = document.querySelector('.header-wrapper');
  }

  onToggle() {
    if (!this.header) return;
    this.header.preventHide = this.mainDetailsToggle.open;

    if (document.documentElement.style.getPropertyValue('--header-bottom-position-desktop') !== '') return;
    document.documentElement.style.setProperty('--header-bottom-position-desktop', `${Math.floor(this.header.getBoundingClientRect().bottom)}px`);
  }
}

customElements.define('header-menu', HeaderMenu);
